body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/background-zebra.svg");
  background-size: cover;
  background-color: #18122b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100vh;
}

@keyframes spinClose {
  from {transform:rotate(0deg);}
  to {transform:rotate(180deg);}
}

@keyframes spinOpen {
  from {transform:rotate(180deg);}
  to {transform:rotate(0deg);}
}

.carousel__dot {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  margin: 5px;
  background-color: transparent;
  background-image: url('./assets/carousel_dot_inactive.png');
  background-size: cover;
  content: "";
  height: 20px;
  width: 20px;
  max-width: 20px;
  border-radius: 50%;
  border: none;
}

.carousel__dot.carousel__dot--selected {
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  margin: 5px;
  background-image: url('./assets/carousel_dot.png');
  background-size: cover;
  content: "";
  height: 20px;
  width: 20px;
  box-shadow: 0 0 1px 3px rgba(103,58,183,1);
  border-radius: 50%;
  border: none;
}

.no-outline-on-focus:focus,
.no-outline-on-focus:focus {
  outline: none;

}

.PhoneInputCountry {
  color: white;
  padding-left: 10px;
}

.MuiSelect-iconOutlined {
  color: #857d9c !important;
}